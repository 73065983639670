<template>
  <div v-if="qandA" style="width: 100%; margin: 0 auto" class="h-100">
    <div v-if="!loading">
      <!-- faq search section -->
      <section id="faq-search-filter">
        <b-card
          no-body
          class="faq-search px-2"
          :style="{
            backgroundImage: `url(${require('@/assets/images/banner/suportbanner.png')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }"
        >
          <b-card-body
            class="text-center d-flex flex-column align-items-center justify-content-center p-0"
          >
            <h2
              class="card_title mb-0"
              :class="{ 'dark-card_title': themeMode === 'dark' }"
            >
              <span>{{ $t("Pages.Support.Title.what_matter") }}</span>
              <span class="text-primary">
                {{ $t("Pages.Support.Title.Question") }}
              </span>
              <span>{{ $t("Pages.Support.Title.or_have_problem") }}</span>
            </h2>
            <b-card-text
              v-show="!selectedItem"
              class="choose-category"
              :class="{ 'dark-choose-category': themeMode === 'dark' }"
            >
              {{ $t("Pages.Support.Title.choose_cat_find_help") }}
            </b-card-text>
          </b-card-body>
        </b-card>
      </section>
      <!--/ faq questions section -->
      <!-- ////start grids//// -->
      <div class="container grid-container">
        <div id="faq-tabs" class="row" v-if="!selectedItem">
          <b-col
            sm="4"
            v-for="(data, index) in qandA"
            :key="index"
            class="column"
            @click="selectItemFaq(data, index)"
          >
            <div class="card-wrapper">
              <b-card class="shadow-none faq-contact-card m-0 text-center">
                <div
                  class="box d-flex flex-column align-items-center justify-content.center"
                >
                  <b-avatar size="" variant="light-primary">
                    <feather-icon
                      :icon="data.category_icon"
                      color="light-primary"
                      size="58"
                      class="border-0"
                    />
                  </b-avatar>
                  <h4 class="m-0">{{ data.category_title }}</h4>
                  <span class="text-body">{{
                    $t("Pages.Support.Title.most_freq_about", {
                      faq: data.category_title,
                    })
                  }}</span>
                </div>
                <b-button
                  variant="primary"
                  size="sm"
                  class="text-center see-button"
                >
                  {{ $t("Pages.Support.Title.See") }}
                </b-button>
              </b-card>
            </div>
          </b-col>
        </div>
      </div>
      <!-- /////end grids//// -->
      <!-- ////faq tab start///// -->
      <section id="faq-tabs" v-if="selectedItem">
        <div class="container faq-tabs-container">
          <!-- ///back button//// -->
          <div class="row">
            <div class="col">
              <div
                role="button"
                class="my-2 text-muted"
                @click="ButtonBack"
                v-if="selectedItem"
              >
                <feather-icon
                  :icon="
                    $i18n.locale == 'en' ? 'ArrowLeftIcon' : 'ArrowRightIcon'
                  "
                  size="20"
                />
                {{ $t("Pages.Support.Button.Back") }}
              </div>
            </div>
          </div>
          <!-- ////header//// -->
          <div class="row">
            <div class="col">
              <div
                class="d-flex justify-content-start align-items-start faq-tab-header"
              >
                <b-avatar
                  rounded
                  size="48"
                  variant="light-primary"
                  class="icon-wrapper"
                >
                  <feather-icon
                    :icon="qandA[selectedIndex].category_icon"
                    size="36"
                  />
                </b-avatar>
                <div>
                  <h4 class="mb-0 text-primary text-left">
                    {{ selectedItem.category_title }}
                  </h4>
                  <span class="text-body"
                    >{{
                      $t("Pages.Support.Title.most_freq_about", {
                        faq: selectedItem.category_title,
                      })
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- /////collaps section -->
          <div class="row">
            <div class="col">
              <app-collapse id="faq-payment-qna" accordion type="margin">
                <app-collapse-item
                  v-for="(data, indexq) in selectedItem.faqs"
                  :key="indexq"
                  :title="data.title"
                  class="collapse-item text-justify mt-0"
                  :class="{ 'dark-collapse-item': themeMode === 'dark' }"
                >
                  {{ data.reply }}
                </app-collapse-item>
              </app-collapse>
            </div>
          </div>
          <!-- /////ask your question//// -->
          <div class="row">
            <div class="col">
              <div
                class="d-flex flex-column flex-sm-row justify-content-start align-items-center ask-question"
                :class="{ 'dark-ask-question': themeMode === 'dark' }"
              >
                <h5>
                  <span>{{ $t("Pages.Support.Title.not_find_answer") }}</span>
                </h5>
                <b-button
                  variant="primary"
                  size="md"
                  class="d-flex ask-button"
                  @click="showButtonLogin"
                >
                  <span>{{ $t("Pages.Support.Title.ask_your_q") }} </span>
                  <feather-icon
                    :icon="
                      $i18n.locale == 'fa' ? 'ArrowLeftIcon' : 'ArrowRightIcon'
                    "
                    size="18"
                  />
                </b-button>
              </div>
            </div>
          </div>
          <!-- ///////login-register-section////////////// -->
          <div v-show="show_button_login" class="row">
            <div class="col">
              <div
                class="login-box d-flex flex-column flex-sm-row justify-content-between align-items-center"
                :class="{ 'dark-login-box': themeMode === 'dark' }"
              >
                <h4 ref="loginRef" class="m-0">
                  <span>{{ $t("Pages.Support.Title.to_ask_must") }}</span>
                  <span class="text-primary">
                    {{ $t("Pages.Support.Title.enter_site") }}
                  </span>
                  <span>{{ $t("Pages.Support.Title.Become") }}</span>
                </h4>
                <div class="row">
                  <div class="col">
                    <div class="d-flex justify-content-end">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline"
                        @click="login"
                        class="login-button btn btn-outline-secondary"
                      >
                        {{ $t("Pages.Support.Button.Login") }}
                        <feather-icon
                          :icon="
                            $i18n.locale == 'fa'
                              ? 'ArrowLeftIcon'
                              : 'ArrowRightIcon'
                          "
                          size="20"
                        />
                      </b-button>

                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="register"
                        class="register-button"
                      >
                        {{ $t("Pages.Support.Button.Register") }}
                        <feather-icon
                          :icon="
                            $i18n.locale == 'fa'
                              ? 'ArrowLeftIcon'
                              : 'ArrowRightIcon'
                          "
                          size="20"
                        />
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center h-100 mt-5"
    >
      <img width="300" src="@/assets/images/assistant/loading-red.gif" />
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
const youtubeCheck = process.env.VUE_APP_YOUTUBE_CHECK === "true";

export default {
  data() {
    return {
      file: null,
      categories: [],
      userChatId: "",
      qandA: [],
      selectedItem: null,
      selectedIndex: null,
      show_button_login: false,
      loading: true,
      themeMode: "dark",
    };
  },
  components: {
    BCol,
    BAvatar,
    BButton,
    AppCollapseItem,
    AppCollapse,
    BCard,
    BCardBody,
    BCardText,
  },
  directives: {
    Ripple,
  },
  methods: {
    getFaqList() {
      this.$http.get("/api/faq/list").then((response) => {
        const result = response.data.result;
        if (youtubeCheck) {
         const filteredFaq = Object.fromEntries(
            Object.entries(result).filter(([key, value]) => key != 6)
          );
          this.qandA=Object.values(filteredFaq)

        } else {
          this.qandA = Object.values(result);
        }
        this.loading = false;
      });
    },
    register() {
      this.$http
        .get("/api/check_login")
        .then(async ({ data }) => {
          if (data.status) {
            this.$router.push({ name: "ticket-new" });
          } else {
            this.$router.push({
              name: "signup",
              query: { redirect: "ticket-new" },
            });
          }
        })
        .catch((err) => {
          this.$router.push({
            name: "signup",
            query: { redirect: "ticket-new" },
          });
        });
    },
    login() {
      this.$http
        .get("/api/check_login")
        .then(async ({ data }) => {
          if (data.status) {
            this.$router.push({ name: "ticket-new" });
          } else {
            this.$router.push({
              name: "login",
              query: { redirect: "ticket-new" },
            });
          }
        })
        .catch((err) => {
          this.$router.push({
            name: "login",
            query: { redirect: "ticket-new" },
          });
        });
    },
    selectItemFaq(item, index) {
      this.selectedItem = item;
      this.selectedIndex = index;
    },
    showButtonLogin() {
      this.show_button_login = true;
      this.$http
        .get("/api/check_login")
        .then(async ({ data }) => {
          if (data.status) {
            this.$router.push({ name: "ticket-new" });
          } else {
            this.show_button_login = true;
            this.$refs.loginRef?.scrollIntoView({ behavior: "smooth" });
          }
        })
        .catch((err) => {
          this.show_button_login = true;
          this.$refs.loginRef?.scrollIntoView({ behavior: "smooth" });
        });
    },

    ButtonBack() {
      this.selectedItem = null;
      this.show_button_login = false;
    },
  },

  mounted() {
    this.getFaqList();
    this.themeMode = localStorage.getItem("vuexy-skin");
  },
};
</script>

<style lang="scss">
.faq-search {
  height: 300px;
  margin-bottom: 50px;
  .dark-card_title {
    color: #8692d0;
  }
  .card_title {
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
  }
  .dark-choose-category {
    color: #ffffff66;
  }
  .choose-category {
    font-size: 12px;
    line-height: 22px;
    margin-top: 16px;
  }
}
.grid-container {
  padding-left: 120px;
  padding-right: 121px;

  .row {
    row-gap: 20px;
    .column {
      padding: 0 8px 0 8px;
    }
    .card-wrapper {
      .card-body {
        border-radius: 6px;
        overflow: hidden;
        border: 1px solid #434968;
        &:hover {
          background: rgba(212, 30, 66, 0.5);
          border-color: #d41e42;
        }
      }

      .faq-contact-card {
        gap: 16px;

        cursor: pointer;
        .box {
          gap: 6px;
          align-self: stretch;
          margin-bottom: 16px;
          border: none;
          background: none;
          h4 {
            color: #8692d0;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            border: none;
            background: none;
          }
          .text-body {
            color: rgba(255, 255, 255, 0.4);
            text-align: center;
            font-size: 13px;
            line-height: 22px;
            border: none;
            background: none;
          }
        }
        .see-button {
          color: #fff;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}
.faq-tabs-container {
  font-family: IRANSansXFaNum;
  padding: 0 221px 0 221px;

  .faq-tab-header {
    margin-bottom: 26px;
    .icon-wrapper {
      padding: 6px;
      margin-right: 16px !important;
    }
    h4 {
      text-align: right;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
    }
    span.text-body {
      color: #8692d0;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .dark-collapse-item {
    background: #2f3349;
    color: #8692d0;
  }
  .collapse-item {
    padding: 12.5px 18px 12.5px 18px;
    text-align: right;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 8px !important;
    border-radius: 6px;
    @media screen and (max-width: 768px) {
      ::after {
        top: 50%;
        right: -18px;
      }
    }
    /* Dark/Gray/Gray - SM */
    box-shadow: 0px 2px 4px 0px rgba(15, 20, 34, 0.4);
    .collapsed {
      padding: 0 !important;
    }
  }
  .dark-ask-question {
    h5 {
      color: #8692d0;
    }
  }
  .ask-question {
    margin-top: 12px;
    margin-bottom: 0;
    padding: 10px 10px 0 10px;
    gap: 25px;
    h5 {
      font-size: 15px;
      font-weight: 700;
      line-height: 22px;
    }
    .ask-button {
      gap: 5px;
      padding: 10px 20px 10px 20px;
      font-size: 15px;
      color: #ffffff;
    }
    @media screen and (max-width: 600px) {
      gap: 16px;
    }
  }
  .dark-login-box {
    background: #2f3349;
    box-shadow: 0px 4px 20px 0px rgba(15, 20, 34, 0.4);
    h4 {
      color: #8692d0;
    }
  }
  .login-box {
    padding: 24px;
    align-items: center;
    gap: 50px;
    border-radius: 6px;
    margin-bottom: 195px;
    margin-top: 30px;
    h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
    .login-button {
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #a8aaae;
      margin-right: 16px;
    }
    .register-button {
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-size: 15px;
      color: #ffffff;
    }
    @media screen and (max-width: 600px) {
      gap: 25px;
    }
  }
  @media screen and (max-width: 768px) {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}
</style>
